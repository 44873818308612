import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'icon'];

  toggle() {
    const isVisible = this.contentTarget.style.display === 'block';

    this.contentTarget.style.display = isVisible ? 'none' : 'block';

    this.iconTarget.classList.toggle('arrow-up');
  }
}
